<template>
  <div>
    <save-consultant-basic-information-sidebar
      :callback="updateConsultantDetails"
    />
    <h1>Informações gerais</h1>
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="details">
      <b-row v-if="editEnabled" class="justify-content-end">
        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click.prevent="goToEditConsultantDetails"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span>Editar</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="details.picture_file_url"
        class="justify-content-center mb-2"
      >
        <b-col cols="auto">
          <b-avatar
            size="200"
            variant="light-primary"
            class="badge-minimal"
            style="cursor: pointer"
          >
            <img
              v-if="details.picture_file_url"
              :src="details.picture_file_url"
              @click.prevent="goToPicture"
              height="240"
            />
          </b-avatar>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Nome </small>
            <h5>
              {{ details.name || "Nome não informado" }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> E-mail </small>
            <h5 v-if="details.email">
              <a :href="'mailto:' + details.email">
                {{ details.email }}
              </a>
            </h5>
            <h5 v-else>E-mail não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row> 
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> CPF </small>
            <h5>
              {{ details.cpf | cpfCnpj }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Celular </small>
            <h5 v-if="details.cell_phone">
              {{ details.cell_phone | formatPhone }}
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; vertical-align: bottom"
                @click.prevent="goToWhatsapp(details.cell_phone)"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  size="16"
                  class="text-body"
                  stroke="green"
                />
              </b-button>
            </h5>
            <h5 v-else>Celular não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Criado em </small>
            <h5>
              {{ details.created_at | dateTime }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Nível </small>
            <h5>
              {{
                details.role + " desde " +
                  (details.role_logs.length > 0
                    ? formatRoleDate(details.role_logs[0].createdAt)
                    : formatRoleDate(details.created_at)
                  ) || "Nível não informado"
              }}
            </h5>
            <template v-if="details.role_logs.length > 0">
              <h5 v-for="(log, index) in details.role_logs">
                <template v-if="details.role_logs[index + 1]">
                  {{
                    log.role_past_name + 
                      " de " + formatRoleDate(details.role_logs[index + 1].createdAt) +
                      " a " + formatRoleDate(log.createdAt)
                  }}
                </template>
                <template v-else>
                  {{
                    log.role_past_name + 
                      " de " + formatRoleDate(details.created_at) +
                      " a " + formatRoleDate(log.createdAt)
                  }}
                </template>
                <!-- <br> -->
              </h5>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Aprovado em </small>
            <h5>
              {{ details.approval_date | onlyDate }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Contrato assinado em </small>
            <h5>
              {{ details.contract_signed_at | dateTime }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Desativado em </small>
            <h5>
              {{ details.deleted_at | dateTime }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Distrato assinado em </small>
            <h5>
              {{ details.contract_termination_signed_at | dateTime }}
            </h5>
          </div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BSpinner,
  BButton,
  BPopover,
  BCardText,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/consultant/store/types";
import * as accountTypes from "@/modules/account/store/types";
import { useToast } from "vue-toastification/composition";
import {
  APP_BACKOFFICE_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_ADMIN_ROLE_ID,
} from "@/constants/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import SaveConsultantBasicInformationSidebar from "@/modules/consultant/components/SaveConsultantBasicInformationSidebar";
export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BButton,
    BPopover,
    BCardText,
    SaveConsultantBasicInformationSidebar,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_GENERAL_INFORMATION,
      user: accountTypes.USER,
    }),
    editEnabled: function () {
      return (
        this.user?.user_role_id == APP_BACKOFFICE_ROLE_ID ||
        this.user?.user_role_id == APP_FINANCIAL_ROLE_ID ||
        this.user?.user_role_id == APP_ADMIN_ROLE_ID ||
        (this.user?.appify_consultant_id &&
          this.user?.appify_consultant_id === parseInt(this.$route.params.id))
      );
    },
  },
  mounted() {
    this.updateConsultantDetails();
  },
  methods: {
    ...mapActions({
      getDetailsGeneralInformation: types.GET_DETAILS_GENERAL_INFORMATION,
      openSaveConsultantBasicInformationSidebar:
        types.OPEN_NEW_CONSULTANT_SIDEBAR,
    }),
    updateConsultantDetails() {
      this.loading = true;
      this.getDetailsGeneralInformation(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações do PN. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.$router.push({ name: "consultants-list" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToWhatsapp(phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + phone, "_blank").focus();
      });
    },
    goToPicture() {
      setTimeout(() => {
        window.open(this.details.picture_file_url, "_blank").focus();
      });
    },
    goToEditConsultantDetails() {
      this.openSaveConsultantBasicInformationSidebar({
        id: this.$route.params.id,
      });
    },
    formatRoleDate(dateString) {
      const date = new Date(dateString);
      return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear().toString().slice(-2)}`;
    }
  },
};
</script>
